/* Playlist. */

.playlist {
    background-color: $color-playlist-bg;
    color: $color-dark-gray;
    padding-top: $spacing-base10;
    position: relative;
    width: 100%;
    @include breakpoint($bp-tablet) {
        padding: $spacing-base8 $bp-desktop-narrow-gutter $bp-desktop-narrow-gutter;
    }
    @include breakpoint($bp-desktop-narrow) {
        padding: 0 0 $spacing-base10;
    }
    &.is-loading {
        .lds-ellipsis {
            @include z-layer(top);
            left: calc(50% - 30px);
            position: absolute;
            top: 200px;
            width: 60px;
            @include breakpoint($bp-desktop-narrow) {
                top: 130px;
            }
        }
        .playlist__tab-panel {
            visibility: hidden;
        }
    }
    .disclaimer-text {
        margin-bottom: $spacing-base10 * 1.2;
        padding: 0 $spacing-base10;
        @include breakpoint($bp-tablet) {
            margin: $spacing-base5 0;
            text-align: left;
        }
    }
    &__tabs {
        border-bottom: 1px solid rgba($color-dark-gray, .25);
        display: inline-block;
        width: 100%;
        @include breakpoint($bp-tablet) {
            width: calc(50% - #{$spacing-base10};
        }
        @include breakpoint($bp-desktop-narrow) {
            width: 100%;
        }
        &__duo {
            .playlist__tab {
                max-width: 50%;
                width: 50%;
            }
        }
    }
    &__tab {
        display: inline-block;
        font-weight: $weight-regular;
        font-size: 1.6rem;
        margin-top: $spacing-base5;
        max-width: 33.3%;
        padding: $spacing-base8 0;
        text-align: center;
        width: 33.3%;
        @include breakpoint($bp-tablet) {
            margin-top: 0;
        }
        @include breakpoint($bp-desktop-narrow) {
            font-size: 1.4rem;
        }
        @include breakpoint($bp-desktop-wide) {
            font-size: 1.8rem;
        }
        &.ui-tabs-active {
            border-bottom: 2px solid $color-white;
            font-weight: $weight-bold;
            a {
                color: $color-body-inverse;
                cursor: default;
                &:focus {
                    outline: none;
                }
            }
        }
        a {
            color: rgba($color-white, .7);
            cursor: pointer;
            text-decoration: none;
            transition: color $transition-speed2 + 0s ease-in-out;
            &:hover {
                color: $color-body-inverse;
            }
        }
    }
    &__filters {
        display: inline-block;
        padding: $spacing-base5 $spacing-base10 13px;
        text-align: left;
        width: 100%;
        @include breakpoint($bp-tablet) {
            padding: $spacing-base8 0 $spacing-base8 $spacing-base10;
            width: calc(50% + #{$spacing-base10});
        }
        @include breakpoint($bp-desktop-narrow) {
            padding: $spacing-base8;
            text-align: left;
            width: 100%;
        }
    }
    &__filter {
        display: inline-block;
        margin-right: $spacing-base8;
        position: relative;
        vertical-align: top;
        width: calc(100% - #{$spacing-base10} * 3);
        @include breakpoint($bp-tablet) {
            text-align: left;
            width: calc(48.75% - #{$spacing-base16};
        }
        @include breakpoint($bp-desktop-narrow) {
            padding-left: 0;
        }
        &:first-child {
            @include z-layer(top);
        }
        .touch-enabled & {
            &:after {
                @include icon-moon;
                @include z-layer(top);
                color: $color-white;
                content: $icon-moon-arrow-down;
                font-size: 10px;
                position: absolute;
                right: $spacing-base10;
                top: $spacing-base10 * 2;
                @include breakpoint($bp-tablet) {
                    content: '';
                }
            }
        }
        &.open {
            .playlist__filter__dropdown {
                @include z-layer(middle);
                height: auto;
                opacity: 1;
                position: absolute;
            }
        }
        &__btn {
            @include button(rgba($color-playlist-bg, .8), rgba($color-white, .25), false, true);
            @include z-layer(middle);
            color: $color-light-gray;
            font-weight: $weight-semibold;
            font-size: 1.2rem;
            height: $spacing-base10 * 3.6;
            letter-spacing: .05rem;
            margin-top: $spacing-base8;
            padding: $spacing-base8 $spacing-base16 $spacing-base8 $spacing-base8;
            position: relative;
            text-align: left;
            width: 100%;
            @include breakpoint($bp-tablet) {
                height: auto;
                margin-bottom: 0;
            }
            &:hover {
                background-color: $color-playlist-bg;
            }
            span {
                font-size: .5rem;
                position: absolute;
                right: $spacing-base5;
                top: $spacing-base10 * 1.2;
            }
        }
        &__dropdown {
            @include z-layer(bottom);
            background-color: $color-playlist-bg;
            border: 1px solid rgba($color-white, .25);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            border-top: 0;
            height: 0;
            margin-top: -1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            width: 100%;
            &:before {
                @include z-layer(middle);
                background-image: linear-gradient(to bottom, $color-playlist-bg, transparent);
                content: '';
                height: 12px;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
            &:after {
                background-image: linear-gradient(to top, $color-playlist-bg, transparent);
                bottom: 0;
                content: '';
                height: 12px;
                left: 0;
                position: absolute;
                right: 0;
            }
            a {
                color: $color-body-inverse;
                cursor: pointer;
                display: inline-block;
                font-size: 1.2rem;
                padding: $spacing-base8;
                padding-bottom: 0;
                text-decoration: none;
                text-transform: uppercase;
                width: 100%;
                &:last-child {
                    padding-bottom: $spacing-base5;
                }
            }
            &__block {
                max-height: 50vh;
                overflow-y: scroll;
                position: relative;
            }
        }
    }
    &__refresh,
    &__slider {
        color: $color-light-gray;
        cursor: pointer;
        display: inline-block;
    }
    &__refresh {
        font-size: 1.7rem;
        margin-top: -$spacing-base5;
        text-align: right;
        width: $spacing-base10 * 2;
        @include breakpoint($bp-tablet) {
            display: inline-block;
            margin-top: $spacing-base10;
        }
    }
    &__slider {
        //display: inline-block;
        display: none;
        font-size: 2rem;
        padding-top: 2px;
        width: auto;
        @include breakpoint($bp-tablet) {
            display: none;
        }
    }
    &__tab-panel {
        display: none;
        &.playlist-tab-1,
        &.ui-tabs-panel {
            display: block;
        }
    }
    &__items {
        @include flexbox;
        @include flex-flow(column wrap);
        @include breakpoint($bp-tablet) {
            @include flex-flow(row wrap);
            @include justify-content(space-between);
            padding-top: $spacing-base10;
        }
        @include breakpoint($bp-desktop-narrow) {
            @include justify-content(flex-start);
            padding: $spacing-base8 $spacing-base8 0;
        }
    }
    &__item {
        @include video-card;
        margin: 0 $spacing-base10 $spacing-base10;
        @include breakpoint($bp-tablet) {
            margin: 0 0 $gutter-tablet;
            width: calc(50% - #{$gutter-tablet}/2)
        }
        @include breakpoint($bp-desktop-narrow) {
            margin-bottom: $spacing-base10;
            width: 100%;
        }
    }
    &__load-more,
    &__full-schedule {
        display: inline-block;
        margin: $spacing-base16 auto;
        text-align: center;
        width: 100%;
        a {
            @include button-load($color-button-highlight, false);
        }
    }
    &__no-results {
        min-height: 30vh;
        padding: $spacing-base10;
        text-align: center;
        &__text {
            font-size: 1.6rem;
            margin: $spacing-base10 * 2 auto $spacing-base10 * 5;
            @include breakpoint($bp-desktop-narrow) {
                margin: $spacing-base10 * 2 auto 100%;
            }
        }
    }
}