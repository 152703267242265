/*
 * Schedule Page.
 */
@charset 'UTF-8';

/* Component import. */
@import '../../components/cards';
@import '../../components/playlist';

/* Variables. */
$return-btn-height: 28px;
$return-btn-width: 84px;

/* Schedule Page Layout. */
.schedule-page {
    background-color: $color-schedule-page-bg;
    &.site-partner {
        .playlist {
            &__return-to-sl {
                display: none;
            }
        }
    }
    @include breakpoint($bp-tablet) {
        .playlist {
            background-color: transparent;
            padding-top: $spacing-base32;
            &__tabs,
            &__filters {
                vertical-align: top;
            }
            &__tabs {
                width: calc(50% - #{$spacing-base10});
            }
            &__tab-panel {
                padding-top: $spacing-base16;
            }
            &__filters {
                padding-left: calc(#{$spacing-base10} * 2);
                padding-right: 0;
                padding-top: 0;
                width: calc(50% + #{$spacing-base10});
            }
            &__filter {
                padding-top: 0;
                width: calc(50% - #{$spacing-base10} * 2.3);
            }
            &__item {
                margin-right: calc(#{$spacing-base10} * 1.5);
                width: calc(50% - #{$spacing-base10});
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
    @include breakpoint($bp-desktop-narrow) {
        &.site-partner {
            .playlist {
                &__return-to-sl {
                    border: 1px solid rgba($color-white, 0.25);
                    border-radius: 2px;
                    color: $color-white;
                    display: inline-block;
                    margin-left: calc(#{$spacing-base10} * 2);
                    margin-top: $spacing-base8;
                    padding: 2px 4px 3px;
                    text-decoration: none;
                    width: $return-btn-width;
                    &__text {
                        display: block;
                        letter-spacing: .5rem;
                        margin-right: -.5rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    .logo-sportslive {
                        background: transparent image-url($logo-sportslive) no-repeat center center;
                        background-size: contain;
                        display: inline-block;
                        height: 8px;
                        width: 100%;
                    }
                }
                &__tabs {
                    width: calc(33% - #{$spacing-base10});
                }
                &__filters {
                    width: calc(66% - #{$return-btn-width}});
                }
                &__filter {
                    padding-top: 0;
                    width: calc(50% - (#{$spacing-base10} * 2.3));
                }
            }
        }
        @include breakpoint($bp-desktop-med) {
            &.site-partner {
                .playlist {
                    &__return-to-sl {
                        margin-left: calc(16% + 20px);
                    }
                    &__filters {
                        width: calc(50% - #{$return-btn-width}});
                    }
                }
            }
        }
        @include breakpoint($bp-desktop-wide) {
            &.site-partner {
                .playlist {
                    &__return-to-sl {
                        margin-left: calc(33% + 20px);
                    }
                    &__filters {
                        padding-left: $spacing-base10;
                        width: calc(33% - #{$return-btn-width}});
                    }
                }
            }
        }
        .playlist {
            &__tab {
                font-size: 1.8rem;
            }
            &__tab-panel {
                padding-top: $spacing-base32;
            }
            &__items {
                padding: 0;
            }
            &__item {
                margin-bottom: $spacing-base10 * 2;
                width: calc(33% - #{$spacing-base10});
                &:nth-child(2n) {
                    margin-right: calc(#{$spacing-base10} * 1.5);
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
                .video-card__header__sport {
                    width: 60%;
                }
                .video-card__header__date {
                    width: 40%;
                }
                .video-card__title {
                    padding-bottom: $spacing-base10 * 3;
                }
            }
        }
    }
    @include breakpoint($bp-desktop-med) {
        .playlist {
            &__tab {
                font-size: 1.8rem;
            }
            &__tab-panel {
                padding-top: $spacing-base32;
            }
            &__items {
                padding: 0;
            }
            &__item {
                margin-bottom: $spacing-base10 * 2;
                width: calc(33% - #{$spacing-base10});
                &:nth-child(2n) {
                    margin-right: calc(#{$spacing-base10} * 1.5);
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
                .video-card__header__sport {
                    width: 65%;
                }
                .video-card__header__date {
                    width: 35%;
                }
            }
        }
    }
    .content-container {
        max-width: $bp-desktop-med-max;
    }
    .title--schedule {
        color: $color-body-inverse;
        font-size: 3rem;
        padding: $spacing-base32 $spacing-base10 0;
        @include breakpoint($bp-tablet) {
            padding-left: $spacing-base10 * 2;
            padding-right: $spacing-base10 * 2;
        }
        @include breakpoint($bp-desktop-narrow) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .playlist {
        &__load-more,
        &__full-schedule {
            a {
                @include button-load(rgba($color-black, .3), true);
            }
        }
    }
}