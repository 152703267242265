/* Footer. */

.footer {
    background-color: $color-main-footer-bg;
    display: block;
    padding: $spacing-base32 0 $spacing-base10 * 10;
    text-align: center;
    width: 100vw;
    @include breakpoint($bp-desktop-narrow) {
        height: 38px;
        padding: 2px;
    }
    &__logo {
        display: block;
        height: 40px;
        margin: 4px auto $spacing-base16;
        text-align: center;
        vertical-align: middle;
        width: auto;
        @include breakpoint($bp-desktop-narrow) {
            display: inline-block;
            height: 30px;
            margin-bottom: 4px;
        }
    }
    &__links {
        @include flex;
        @include justify-content(center);
        padding: 0 $spacing-base16;
        vertical-align: middle;
        @include breakpoint($bp-desktop-narrow) {
            display: inline-block;
            padding: 0 $spacing-base5;
        }
        &__link {
            color: rgba($color-body-inverse, .8);
            display: inline-block;
            font-size: 1.1rem;
            padding: 3px 0;
            a {
                border-left: 1px solid rgba($color-body-inverse, .8);
                color: rgba($color-body-inverse, .8);
                display: inline-block;
                padding: 0 4px 0 $spacing-base5;
                text-decoration: none;
                transition: color $transition-speed + 0s ease-in-out;
                &:hover {
                    color: $color-body-inverse;
                }
            }
            &__copy {
                color: rgba($color-body-inverse, .8);
                display: inline-block;
                @include breakpoint($bp-desktop-narrow) {
                    padding: 0 $spacing-base5 0 $spacing-base10;
                }
            }
        }
    }
}