/* Cards. */

@mixin video-card() {
    background-color: $color-video-card-bg;
    border: 1px solid rgba($color-black, .2);
    display: inline-block;
    min-height: 95px;
    padding: $spacing-base10;
    position: relative;
    width: calc(100% - #{$spacing-base10} * 2);
    &:hover {
        cursor: pointer;
    }
    @include breakpoint($bp-tablet) {
        width: 100%;
    }
    &.is-live {
        .video-card__header__date {
            color: $color-red;
            font-family: $font-family-base;
            font-weight: $weight-bold;
            letter-spacing: 0.3rem;
            text-transform: uppercase;
        }
        @include breakpoint($bp-tablet) {
            .video-card__header__sport {
                width: 80%;
            }
            .video-card__header__date {
                width: 20%;
            }
        }
        &.is-playing {
            border-color: $color-red;
            .video-card__header__date {
                &:before {
                    @include animation(blinker, $transition-speed4 + 0s, ease-in-out, 0s, infinite);
                    background-color: $color-red;
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: $spacing-base5;
                    margin-right: $spacing-base5;
                    margin-top: -2px;
                    vertical-align: middle;
                    width: $spacing-base5;
                }
            }
        }
    }
    &.is-playing {
        background-color: rgba($color-black, 0.5);
        .video-card__footer__watch,
        .video-card__footer__subscribe,
        .video-card__footer__listen,
        .video-card__footer__free {
            display: none;
        }
        .video-card__footer__playing {
            display: inline;
        }
    }
    &.has-img {
        display: inline-block;
        padding-bottom: $spacing-base10;
        position: relative;
        @include breakpoint($bp-desktop-narrow) {
            padding-top: $spacing-base5;
        }
        @include breakpoint($bp-desktop-med-max) {
            padding-top: $spacing-base10;
        }
        @include breakpoint($bp-desktop-wide) {
            padding-top: $spacing-base5;
        }
        @include breakpoint($bp-desktop-xwide) {
            padding-top: $spacing-base10;
        }
        .video-card {
            &__header {
                padding-bottom: $spacing-base5;
                width: 100%;
                @include breakpoint($bp-tablet-min) {
                    float: right;
                    padding-bottom: 0;
                    width: calc(75% - #{$spacing-base10};
                }
                @include breakpoint($bp-tablet) {
                    padding-bottom: $spacing-base5;
                    width: 100%;
                }
                @include breakpoint($bp-desktop-med-max) {
                    width: calc(70% - #{$spacing-base10};
                }
                @include breakpoint($bp-desktop-wide) {
                    width: 100%;
                }
                @include breakpoint($bp-desktop-xwide) {
                    width: calc(70% - #{$spacing-base10};
                }
            }
            &__img {
                display: block;
                float: left;
                &__figure {
                    background-color: $color-off-black;
                    height: 0;
                    padding-bottom: $video-aspect-ratio;
                    position: relative;
                    @include breakpoint($bp-desktop-narrow) {
                        padding-bottom: $video-aspect-ratio;
                    }
                }
            }
            &__details {
                float: right;
                width: calc(70% - #{$spacing-base10};
                @include breakpoint($bp-tablet-min) {
                    width: calc(75% - #{$spacing-base10};
                }
                @include breakpoint($bp-tablet) {
                    width: calc(70% - #{$spacing-base10};
                }
            }
            &__title {
                padding-bottom: $spacing-base10 * 2.5;
                padding-top: 0;
                @include breakpoint($bp-mobile-plus-max) {
                    padding-bottom: $spacing-base10 * 4;
                }
                @include breakpoint($bp-tablet-min) {
                    padding-bottom: $spacing-base10 * 6;
                    padding-top: $spacing-base5;
                }
                @include breakpoint($bp-tablet) {
                    padding-bottom: $spacing-base5;
                    padding-top: 0;
                }
                @include breakpoint($bp-desktop-narrow) {
                    padding-bottom: $spacing-base10 * 2.5;
                }
            }
            &__footer {
                bottom: 10px;
                width: calc(70% - #{$spacing-base10} * 2);
                @include breakpoint($bp-tablet-min) {
                    width: calc(75% - #{$spacing-base10} * 2);
                }
                @include breakpoint($bp-tablet) {
                    width: calc(70% - #{$spacing-base10} * 2;
                }
                &__logos {
                    width: 40px;
                }
                &__logo {
                    max-height: 25px;
                    max-width: 25px;
                    top: -$spacing-base5;
                }
                &__cta {
                    width: calc(100% - 40px);
                }
            }
        }
    }
    &.has-img-logo {
        .video-card__img {
            display: block;
            &__img {
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                margin-top: $spacing-base5;
                padding-bottom: calc(#{$video-aspect-ratio} - #{$spacing-base10});
                width: 100%;
                @include breakpoint($bp-desktop-narrow) {
                    padding-bottom: calc(#{$video-aspect-ratio} - #{$spacing-base10});
                }
            }
        }
    }
    .video-card {
        &__img {
            display: none;
            margin-right: $spacing-base10;
            max-width: 30%;
            overflow: hidden;
            width: 30%;
            @include breakpoint($bp-tablet-min) {
                width: 25%;
            }
            @include breakpoint($bp-tablet) {
                width: 230%;
            }
            &__figure {
                position: relative;
            }
            &__time {
                background-color: $color-black;
                bottom: 0;
                color: $color-white;
                padding: 1px 3px;
                position: absolute;
                right: 0;
            }
        }
        &__details {
            width: 100%;
        }
        &__header {
            font-family: $font-family-cond;
            width: 100%;
            & > * {
                display: inline-block;
                font-size: 1.3rem;
                @include breakpoint($bp-mobile-max) {
                    font-size: 1.4rem;
                }
            }
            &__sport {
                color: rgba($color-white, .35);
                font-weight: $weight-regular;
                letter-spacing: .2rem;
                text-transform: uppercase;
                width: 65%;
            }
            &__date {
                color: rgba($color-white, .5);
                font-weight: $weight-regular;
                letter-spacing: .05rem;
                text-align: right;
                vertical-align: top;
                width: 35%;
            }
        }
        &__title {
            padding: $spacing-base5 0;
            &__text {
                color: $color-white;
                font-size: 1.3rem;
                font-weight: $weight-semibold;
                letter-spacing: .05rem;
                line-height: 1.2;
            }
        }
        &__footer {
            bottom: $spacing-base10;
            font-family: $font-family-cond;
            font-size: 1.4rem;
            position: absolute;
            right: $spacing-base10;
            text-transform: uppercase;
            width: calc(100% - #{$spacing-base10} * 2);
            & > * {
                display: inline-block;
                vertical-align: bottom;
            }
            &__logos {
                font-size: 1.2rem;
                width: 35%;
                &__text {
                    padding: 0 $spacing-base8;
                }
            }
            &__logo {
                display: inline-block;
                margin-bottom: -$spacing-base8;
                max-height: 32px;
                max-width: 32px;
                position: relative;
                vertical-align: bottom;
                &:hover {
                    .video-card__footer__logo__tool-tip {
                        display: block;
                    }
                }
                &__tool-tip {
                    @include z-layer(top);
                    background-color: darken($color-blue, 10%);
                    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.45);
                    color: $color-body-inverse;
                    display: none;
                    font-family: $font-family-base;
                    left: 50%;
                    padding: 3px $spacing-base5;
                    position: absolute;
                    text-align: center;
                    transform: translateX(-50%);
                    &:before {
                        @include triangle(top, darken($color-blue, 10%), .5em);
                        left: 50%;
                        margin: 0;
                        top: -0.6em;
                        transform: translateX(-50%);
                    }
                }
            }
            &__cta {
                text-align: right;
                width: 65%;
                & * {
                    display: inline-block;
                    &:hover {
                        cursor: pointer;
                    }
                }
                & > *:not(:first-child) {
                    margin-left: $spacing-base16;
                }
                span {
                    color: $color-white;
                    font-size: 1.7rem;
                    margin-right: $spacing-base5;
                }
                a {
                    text-decoration: none;
                }
                &__link {
                    color: $color-link;
                }
                .is-subscribed & {
                    .video-card__footer__free,
                    .video-card__footer__subscribe {
                        display: none;
                    }
                }
            }
            &__playing {
                background-color: $color-link;
                border-radius: 2px;
                color: $color-white;
                display: none;
                padding: 1px $spacing-base8;
            }
        }
    }
}