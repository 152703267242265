/* Ads. */

.leaderboard-sticky {
    @include z-layer(modal);
    background-color: $color-black;
    bottom: 0;
    display: block;
    left: 0;
    overflow: hidden;
    position: fixed;
    text-align: center;
    width: 100vw;
    &:before {
        background-image: linear-gradient(to top, $color-black, transparent);
        content: '';
        height: $spacing-base10;
        left: 0;
        position: absolute;
        right: 0;
        top: -$spacing-base10;
    }
    @include breakpoint($bp-desktop-narrow) {
        @include z-layer(middle);
        background-color: transparent;
        padding-top: $spacing-base16;
        position: relative;
        width: 100%;
        &:before {
            content: none;
        }
    }
}
[class*="ad-leader-"] {
    @include clearfix;
    display: block;
    max-width: 100vw;
    position: relative;
    text-align: center;
    iframe {
        margin: 0 auto !important; // Needed to override inline ad styles.
    }
}

[class*="ad-mobile-"] {
    margin: 0 auto;
    width: 320px;
}