/*
 * Main Stylesheet.
 */
@charset 'UTF-8';

/* Base import. */
@import 'core/variables';
@import 'theme/theme';
@import 'core/mixins';
@import 'core/reset';
@import 'core/breakpoints';
@import 'core/image-paths';
@import 'core/iconmoon';
@import 'core/functions';
@import 'core/typography';
@import 'core/animations';
@import 'core/base';


/* 02. Lib. */
@import 'lib/flex';
@import 'lib/jquery';

/* 03. Patterns. */
@import 'patterns/buttons';
@import 'patterns/headings';
@import 'patterns/images';
@import 'patterns/layout';
@import 'patterns/social';


/* Component import. */
@import 'components/ads';
@import 'components/cards';
@import 'components/header';
@import 'components/playlist';
@import 'components/videos';
@import 'components/footer';

/* Template import. */
@import 'template/video/video-page';
@import 'template/schedule/schedule-page';
