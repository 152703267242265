/* Header. */

$nav-dropdown-width: 310px;
$triangle-size: 6px;
$nav-height: 42px;
$color-login: rgba($color-white, 0.55);
$color-login-hover: rgba($color-white, 0.8);

body {
    &.nav-expanded {
        position: fixed;
    }
}
.main-header {
    width: 100vw;
}
.main-nav {
    @include align-items(center);
    @include box-shadow(standard);
    @include flexbox;
    @include flex-flow(row nowrap);
    @include justify-content(space-between);
    @include z-layer(modal);
    background-color: $color-main-nav-bg;
    height: $nav-height;
    padding: 0 $spacing-base16;
    position: -webkit-sticky; /* Safari. */
    position: sticky;
    width: 100vw;
    @include breakpoint($bp-desktop-narrow) {
        height: auto;
        padding: 0 $bp-desktop-narrow-gutter;
    }
    @include breakpoint($bp-desktop-wide) {
        padding: 0 $bp-desktop-wide-gutter;
    }
    @include breakpoint($bp-desktop-xwide) {
        padding: 0 $bp-desktop-xwide-gutter;
    }
    &__logo {
        @include background($color-main-nav-bg image-url($site-logo) no-repeat center left);
        @include z-layer(nav);
        background-size: contain;
        height: calc(#{$site-logo-height} - 8px);
        margin-bottom: 5px;
        margin-top: 5px;
        max-height: $site-logo-height;
        max-width: 50vw;
        width: $site-logo-width;
        @include breakpoint($bp-tablet) {
            height: 3.5vw;
            max-width: $site-logo-width;
        }
        @include breakpoint($bp-desktop-narrow) {
            height: 3vw;
            width: 33vw;
        }
    }
    &__login {
        background-color: $color-mobile-nav-bg;
        display: block;
        font-size: 1.8rem;
        font-weight: $weight-semibold;
        opacity: 0.5;
        padding: $spacing-base16;
        position: absolute;
        right: -100vw;
        top: $nav-height;
        transition: all $transition-speed + 0s ease-in-out;
        width: 100vw;
        @include breakpoint($bp-tablet) {
            @include flexbox;
            @include justify-content(flex-end);
            background-color: transparent;
            font-size: 1.3rem;
            height: auto;
            opacity: 1;
            padding: 0;
            position: relative;
            right: auto;
            text-align: right;
            top: 0;
            visibility: visible;
            width: 50vw;
        }
        @include breakpoint($bp-desktop-narrow) {
            width: 47.5vw;
        }
        @include breakpoint($bp-desktop-wide) {
            width: 34vw;
        }
        .nav-expanded & {
            @include z-layer(modal);
            height: 100vh;
            opacity: 1;
            right: 0;
            width: 100vw;
        }
        &__dropdown {
            font-size: 1.5rem;
            padding-top: $spacing-base5;
            text-align: left;
            @include breakpoint($bp-tablet) {
                display: none;
                left: 0;
                padding-top: $spacing-base8;
                position: absolute;
                top: 25px;
                &:before {
                    @include triangle(top, $color-navy, $triangle-size);
                    left: 50%;
                    margin: 0;
                    top: 0;
                    transform: translate(-50%);
                }
            }
            &__inner {
                padding-left: $spacing-base16;
                &:not(:first-child) {
                    padding-top: $spacing-base5;
                }
                @include breakpoint($bp-tablet) {
                    @include box-shadow();
                    background-color: $color-navy;
                    font-size: 1.4rem;
                    padding: $spacing-base8 $spacing-base16;
                    &:not(:first-child) {
                        padding-top: $spacing-base8;
                    }
                }
            }
            a {
                display: inline-block;
                padding: $spacing-base5 0;
                width: 100%;
            }
        }
        & > * {
            color: $color-login;
            margin-bottom: $spacing-base16;
            position: relative;
            vertical-align: middle;
            @include breakpoint($bp-tablet) {
                display: inline-block;
                margin-bottom: 0;
            }
            &:not(:last-child) {
                margin-right: $spacing-base5;
                @include breakpoint($bp-desktop-wide) {
                    margin-left: $spacing-base8;
                }
            }
            &:hover {
                span[class^="icon-moon-"] {
                    &:before {
                        color: $color-login-hover;
                    }
                }
                .text {
                    color: $color-login-hover;
                }
            }
            span[class^="icon-moon-"] {
                position: absolute;
                left: -1px;
                top: -1px;
                &:before {
                    color: $color-login;
                    font-size: 2.6rem;
                    top: 0;
                    position: absolute;
                }
            }
            .text {
                color: $color-login;
                @include breakpoint($bp-tablet) {
                    text-indent: 30px;
                }
            }
        }
        a {
            color: $color-login;
            display: inline-block;
            height: 100%;
            text-decoration: none;
            transition: color $transition-speed + 0s ease-in-out;
            width: 100%;
            span:before,
            .text {
                color: $color-login;
                transition: color $transition-speed + 0s ease-in-out;
            }
            &:hover {
                color: $color-login-hover;
                span:before,
                .text {
                    color: $color-login-hover;
                }
            }
        }
        &--login {
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($bp-tablet) {
                @include button(transparent);
                width: auto;
            }
            .is-logged-in & {
                display: none;
            }
        }
        &--my-account {
            display: none;
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($bp-tablet) {
                @include button(transparent, transparent, true);
                display: none;
                width: auto;
                &:hover {
                    color: $color-login-hover;
                    &:before {
                        color: $color-login-hover;
                    }
                }
                .text {
                    text-indent: 38px;
                }
            }
            &:hover {
                .main-nav__login__dropdown {
                    @include z-layer(top);
                    display: block;
                }
            }
            .is-logged-in & {
                display: inline-block;
                @include breakpoint($bp-tablet) {
                    display: inline-block;
                }
            }
            span {
                display: none;
                @include breakpoint($bp-tablet) {
                    display: inline-block;
                }
            }
        }
        &--subscribe {
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($bp-tablet) {
                @include button(transparent, $color-login);
                width: auto;
            }
            .is-subscribed & {
                display: none;
            }
        }
        &--help {
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($bp-tablet) {
                @include button(transparent, $color-login, true);
                width: auto;
            }
            &:hover {
                border-color: $color-login-hover;
            }
            span {
                display: none;
                @include breakpoint($bp-tablet) {
                    display: inline-block;
                }
            }
        }
        &--facebook,
        &--twitter {
            @include button(transparent);
            height: 26px;
            width: 26px;
        }
        &--info {
            text-transform: uppercase;
            width: 100%;
            @include breakpoint($bp-tablet) {
                @include button(transparent);
                height: 26px;
                width: 26px;
            }
            .icon-moon-info:before {
                display: none;
                font-size: 2.8rem;
                @include breakpoint($bp-tablet) {
                    display: block;
                }
            }
            .text {
                color: $color-login;
                @include breakpoint($bp-tablet) {
                    display: none;
                }
            }
        }
        &--sl {
            background: transparent image-url($logo-sl) no-repeat left top;
            width: 38px;
            @include breakpoint($bp-tablet) {
                background-position: center top;
            }
            @include breakpoint($bp-desktop-wide) {
                margin-left: $spacing-base8;
            }
            a {
                height: 30px;
                @include breakpoint($bp-tablet) {
                    height: 100%;
                }
            }
        }
    }
    &__burger {
        @include breakpoint($bp-tablet) {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
        .icon-moon-nav-bars {
            color: $color-white;
            font-size: 2rem;
            .nav-expanded & {
                &:before {
                    content: $icon-moon-close;
                    font-size: 2.4rem;
                }
            }
        }
    }
}