/*
 * Video Page.
 */
@charset 'UTF-8';

/* Component import. */
@import '../../components/cards';
@import '../../components/playlist';
@import '../../components/videos';

/* Video Page Layout. */

.home-page {
    background-color: $color-video-page-bg;
}
.block-video-player {
    width: 100vw;
    @include breakpoint($bp-desktop-narrow) {
        max-width: 66%;
        width: 66%;
    }
    @include breakpoint($bp-desktop-wide) {
        max-width: 75%;
        width: 75%;
    }
}
.block-playlist {
    width: 100vw;
    @include breakpoint($bp-desktop-narrow) {
        max-width: 34%;
        width: calc(34% - #{$bp-desktop-narrow-gutter};
    }
    @include breakpoint($bp-desktop-wide) {
        max-width: 25%;
        width: calc(25% - #{$bp-desktop-wide-gutter};
    }
}
.hero-video-text {
    @include flexbox;
    border-top: 1px solid rgba($color-dark-gray, .2);
    display: block;
    padding: $spacing-base10;
    text-align: center;
    width: 100vw;
    @include breakpoint($bp-tablet) {
        padding: $spacing-base10 * 2;
        padding-bottom: $spacing-base5;
    }
    @include breakpoint($bp-desktop-narrow) {
        border-bottom: 1px solid rgba($color-dark-gray, .2);
        border-top: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    & > * {
        display: inline-block;
    }
    &__live {
        display: none;
        .is-live & {
            display: inline-block;
        }
        &__btn {
            @include button-live;
            display: inline-block;
            margin-right: $spacing-base16;
        }
    }
    &__desc {
        color: $color-body-inverse;
        font-size: 1.2rem;
        margin-top: -1px;
        text-align: center;
        width: 100%;
        @include breakpoint($bp-tablet) {
            font-size: 1.6rem;
        }
        @include breakpoint($bp-desktop-narrow) {
            font-size: 1.8rem;
        }
        @include breakpoint($bp-desktop-xwide) {
            font-size: 2.2rem;
        }
        .is-live & {
            text-align: left;
            width: auto;
        }
    }
    &__live-text {
        width: 100%;
    }
    .disclaimer-text {
        margin-top: $spacing-base10;
        @include breakpoint($bp-tablet) {
            margin-top: $spacing-base5;
            text-align: right;
        }
        &:hover {
            color: rgba($color-white, .8);
        }
    }
}
.hero-video {
    &__msg {
        @include background($color-black image-url($default-video-bg) no-repeat);
        background-size: cover;
        color: $color-white;
        font-weight: $weight-semibold;
        height: 0;
        padding-bottom: $video-aspect-ratio;
        position: relative;
        text-align: center;
        width: 100%;
        &__text {
            left: 50%;
            position: absolute;
            text-transform: uppercase;
            top: 50%;
            transform: perspective(1px) translate(-50%, -50%);
            width: 80%;
        }
        h5 {
            display: inline-block;
            font-size: 1.6rem;
            padding-bottom: $spacing-base10;
        }
        h6 {
            font-family: $font-family-cond;
            font-size: 1.6rem;
            letter-spacing: .1rem;
        }
    }
}