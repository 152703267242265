/* Videos. */
.embed-video-player {
    &.is-responsive {
        overflow: hidden;
        padding-top: $video-aspect-ratio;
        position: relative;
        width: 100%;

        iframe {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }
}